.recommendedProductCard{
  // height: 123px;
  // width: 30%;
  // padding: 0 5px;
  // flex: 0 0 30%;

  padding: 0 5px;
  flex: 0 0 125px;
  width: 125px;

}

.imageContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  // margin-left: 20px;

}

.recommendCard{
  // width: 110px !important;
  // height: 110px !important;

}
.image{
  object-fit: contain;
    border-radius: 5px;
}
.recommendedAddBasketButton {
  position: absolute;
  bottom: 9px;
  right: 9px;
  transform: translate(50%, 50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #50c878;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  z-index: 1;
}
.price{
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  font-weight: bold;
  background-color: rgb(255 255 255 / 62%);
  border-radius: 5px 0 5px 0;
}

