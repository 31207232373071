.blank-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.animation-container {
	width: 300px;
	height: 300px;
}

.text {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	color: #000000;
	padding: 0 10px;
	margin-bottom: 16px;
}

.button {
	padding: 8px 16px;
	font-size: 16px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.matop {
	margin-top: 20%;
}

.image {
	width: 100%;
}
