.box {
	position: relative;
	border-radius: 5px;
	.skeleton {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	&::after {
		content: " ";
		// position: absolute;
		padding-bottom: 40%;
		display: block;
	}
}

.imageBox {
	.image {
		border-radius: 5px;
		object-fit: cover;
	}
	&::after {
		padding-bottom: 40% !important;
	}
}
