.languageOptionImage {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  .customOption {
    display: flex;
    align-items: center;
  }
  
.menu-portal-target {
  position: relative;
}

.languageSelect {
  position: absolute;
  z-index: 99;
  width: 130px;
  
}
.langOptions{
  width: max-content !important; 
  margin-right: 30px;
  font-size: 11px;
  padding: 0 !important;
  overflow-x: auto;
}
