.box{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -35px;
    right: -30px;
    .heart{
        position: absolute;
        top: 0px;
        right:0;
        z-index: 10;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 40px;
            height: 30px;
        }
    }
    
    .number{
        position: absolute;
        color: red;
        top: 3px;
    }
    
}

