// .CardImage {
//   border-radius: 5px;
//   // height: 100% !important;
// width: 100px;
// }

// .productCard {
//   height: 123px;
//   box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);

//   // border-radius: 10px;
// }
// .radius{
//   border-radius: 10px !important;

// }
// .orgPrice {
//   font-size: 16px;
//   text-decoration: line-through;
//   color: #989898;

// }

// .price {
//   position: relative;
//   margin-left: 5px;
// }

// .cardText {
//   font-size: 1rem !important;
// }
// .cardBody{
//   padding: 0 10px;
//   flex: 1;
// }

.wrappers {
	max-width: 100%;
}

// .CardImage {
//   border-radius: 10px 0 0 10px;
//   height: 100% !important;
//   height: 123px;
//   width: 123px;
//   object-fit: cover;
// }

.productCard {
	box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
	// height: 123px;
	border-radius: 10px;
}
.radius {
	border-radius: 10px !important;
}
.orgPrice {
	font-size: 16px;
	text-decoration: line-through;
	color: #989898;
}

.price {
	position: relative;
	margin-left: 5px;
}

.cardText {
	font-size: 1rem !important;
}
.cardBody {
	padding: 5px 10px;
	flex: 1;
	position: relative;
}

.addBasketButton {
	// position: absolute ;
	// bottom: 10px;
	// left: 10px ;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #50c878;
	color: white;
	font-size: 1rem;
	position: relative;
	cursor: pointer;
	border: none;

	&::before {
		content: "+";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 2.5rem;
	}
}

.showItem {
	position: absolute;
	visibility: hidden;
	z-index: -10;
	opacity: 0;
	// top: 0;
	// right: 0;
	bottom: -24px;
	top: auto;
	right: 0;
	transition: all 0.3s;
}
.visible {
	visibility: visible;
	z-index: 10;
	opacity: 1;
}
.button-cart {
	position: relative;
}

.allergies {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 25px;

	.allergies_item {
		display: flex;
		padding: 5px;
		align-items: center;
		.allergies_icon {
			width: 20px;
			height: 20px;
			min-height: unset;
			margin-right: 2px;
		}
	}
}
