// .red-bar {
//     background-color: #ff5252;
//     overflow-x: scroll;
//     white-space: nowrap;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 10px 0;
//     height: 10%;
//   }
  
//   .red-bar-content {
//     display: flex;
//     padding: 10px;
//     margin-left:50px ;
//   }
  
//   .red-bar-content .scrolling-text {
//     color: white;
//     margin: 0 10px;
//     font-size: 16px;
//   }
  
//   .line-div {
//     width: 100%;
//     height: 1px;
//     background-color: black;
//   }
//   .mtop{
//     margin-top: 30px !important;
//   }
.list-container{
  overflow: hidden;

  .list-wrap{
    display: flex;
    overflow-x: auto;
    padding: 15px 0;
    margin: 0 -10px;
    // padding-right: 17px;
    li{
      list-style: none;
      margin: 0 10px;
      &.active{
        border-bottom: 1px solid  #ff5252;
      }
    }
}
}
#list-parent{
  margin-top: -1.5rem!important;
}