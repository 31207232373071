// .bottom-navigation {
//   position: fixed;
//     bottom: 0;
//     left: 50%;
//     padding: 10px 0;
//     display: flex;
//     justify-content: center;
//     z-index: 99;
//     right: 50%;
//   }
  
//   .navigation-link {
//     display: flex;
//     align-items: center;
//     text-decoration: none;
//     color: #ffffff;
//   }
  
//   .circle {
//     position: relative;
//     width: 90px;
//     height: 90px;
//     background-color: #ff5252;
//     border-radius: 50%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
  
//   .icon {
//     font-size: 40px;
//     color: #f5f5f5;
//   }
  
//   .bottomBadge {
//     color: #ff5252;
//     background-color: white;
//     border-radius: 50% !important;
//     font-size: 22px !important;
//     position: absolute;
//     width: 50%;
//     height: 50%;
//     text-align: center;
//     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   }
  

  .bottom-navigation {
    position: fixed;
    bottom: 0;
    left: 50%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    z-index: 99;
    right: 50%;
  }
  
  .navigation-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
  }
  
  .circle {
    position: relative;
    width: 70px;
    height: 70px;
    background-color: #ff5252;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    font-size: 25px;
    color: #f5f5f5;
  }
  
  .bottomBadge {
    color: #ff5252;
    background-color: white;
    border-radius: 50% !important;
    font-size: 22px;
    position: absolute;
    width: 50%;
    height: 50%;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  