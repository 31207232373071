.box {
	display: flex;
	flex-direction: column;
	z-index: 100;
	.value {
	}
	.label {
	}
	.popupContainer {
		display: flex;
		flex-direction: column;
	}
	.popupBox {
		position: relative;
		.popup {
			position: absolute;
			background-color: #fff;

			.items {
				max-height: 250px;
				// height: 100%;
				overflow: auto;
				.item {
					padding: 1px 10px;
					margin-bottom: 5px;
					&.active {
						background-color: #eee;
					}
				}
			}
		}
	}
}
.searchBox {
	position: relative;
	svg {
		position: absolute;
		left: 11px;
		top: 11px;
		// color:#777;
	}
}
.input {
	border-radius: 5px;
	padding-left: 36px;
	box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
	border: 0;
	height: 40px;
	&.open {
		border-radius: 5px 5px 0 0;
	}
}
