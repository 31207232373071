.cardBox {
    position: relative;
    .heart{
        position: absolute;
        top: 0;
        right: -3px;
        z-index: 10;
        color: red;
        svg{
            width: 25px;
            height: 25px;
        }
    }
}