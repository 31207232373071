.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

/* ! New Year */

.flake {
	margin-top: -33px;
	margin-bottom: 33px;
}

.colorRed {
	color: red;
}

.priceBadge {
	margin-left: 2px;
	font-size: 1rem !important;
}

/* .oldPriceBadge {
  background-color: #db969d !important;
  text-decoration: line-through;

} */
.oldPriceBadge {
	position: relative;
	display: inline-block;
	padding: 5px 10px;

	color: #fff;
	font-size: 12px;
	background-color: #db969d !important;
}

.oldPriceBadge::before {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	background-color: #000;
	position: absolute;
	top: 50%;
	left: -1px;
	transform: translateY(-80%) rotate(-15deg);
}

li {
	list-style: none;
}
ol,
ul {
	padding-left: 0;
}
p {
	margin-bottom: 0;
}
#root {
	min-height: 100vh;
}
