.device {
	@media (min-width: 769px) {
		//    display: none;
		position: absolute;
		visibility: hidden;
		top: -100vh;
	}
}
.checkDevice {
	@media (max-width: 768px) {
		display: none;
	}
}
