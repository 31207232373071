.body {
	.cartItems {
		flex-direction: column-reverse;
		display: flex;
		// height: calc(100vh - 360px);
		// height: calc(100vh - 405px);
		// height: calc(100% - 350px);
		// height: calc(100vh - 310px);
		// overflow: auto;
		// padding: 0 10px 20px;
		// padding-bottom: 337px;
		padding-top: 50px;
		padding-bottom: 360px;

		// margin-bottom: 10px;
		// display: flex;
		// flex-direction: column;
		&.full {
			padding-bottom: 190px;

			// height: calc(100vh - 238px);
			// padding-bottom: 195px;
			// height: calc(100% - 180px);
			// height: calc(100vh - 150px);
		}
	}
}
.navbar {
	position: fixed;
	width: 100%;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	border-bottom: 1px solid #eee;
}
.discount {
	color: #50c878 !important;
	&.active {
		background-color: #50c878 !important;
		color: #fff !important;
		padding: 15px 10px !important;
	}
	font-weight: 500;
	.icon {
		margin-right: 5px;
	}
	.label {
	}
}
.calculatorBox {
	border-radius: 15px 15px 0 0 !important;
}

.beforePrice {
	text-decoration: line-through;
	color: #8c8c8c;
	font-size: 14px;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
	user-select: none;
}

.empty-space {
	height: 330px;
}

.modalTitle {
	font-size: 12px;
}
.dialogClassName {
	width: 90%;
	margin: auto;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modalText {
	font-size: 13px;
}

.button_box {
	display: flex;
	margin: 0 -5px 0;

	button {
		font-size: 13px;
		margin: 20px 5px 0;
		flex: 2;
		&:first-child {
			flex: 1;
		}
	}
}

.discount {
	color: #50c878 !important;
	&.active {
		background-color: #50c878 !important;
		color: #fff !important;
		padding: 15px 10px !important;
	}
	font-weight: 500;
	.icon {
		margin-right: 5px;
	}
	.label {
	}
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
	user-select: none;
}

.fixedBottom {
	position: fixed;
	width: 100%;
	z-index: 100;
	bottom: 0;
	left: 0;
	background-color: #fff;
}
