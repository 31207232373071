.terms {
	p.p1 {
		margin: 0px 0px 8px 0px;
		font: 12px Helvetica;
		color: #000000;
	}

	p.p1 {
		margin: 0px 0px 2px 0px;
		font: 16px Helvetica;
		color: #191c1f;
	}

	p.p2 {
		margin: 0px 0px 5px 0px;
		font: 10px Helvetica;
		color: #191c1f;
	}

	span.s1 {
		font-variant-ligatures: common-ligatures;
	}

	a {
		text-decoration: underline;
		color: #0000ff;
	}
}
