.box {
	position: relative;
	border-radius: 5px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.skeleton {
		width: 100%;
		height: 100%;
		position: absolute;
		opacity: 0;
		transition: 0.5s ease-in-out;
		:global {
			.react-loading-skeleton {
				line-height: normal !important;
			}
		}
		&.show {
			opacity: 1;
		}
	}
	img {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		opacity: 0;
		object-fit: contain;
		transition: 0.5s ease-in-out;
		&.show {
			opacity: 1;
		}
		&.fit-cover {
			object-fit: cover;
		}
	}
	&::after {
		content: " ";
		// position: absolute;
		padding-bottom: 100%;
		width: 100%;
		display: block;
	}
}

.box {
	.test {
	}
}
