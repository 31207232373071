.title{
    font-size: 15px;
}
.box {
    // position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // background-color: #fff;
    padding: 10px;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 25px;
    .row {
        display: inline-flex;
        flex-wrap: nowrap;
        gap: 10px;
      }
  }
  

  .scrollPadding{
    padding: 20px 0px !important;
  }
  
  .fixedBottom{
    height: max-content;
    border-radius: 0px;
    box-shadow: 10px -1px 10px rgb(0 0 0 / 8%);
  }
  .fixedBottom{
  
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  }  
  
  .maxContent{
    height: max-content !important;
  }