
.container{
    padding: 15px;
    background-color: #fff;
}

.headline{
    font-size: 1.5rem;
}

.paragraph{
   margin-top: 2rem;
   line-height: 2;
   font-weight: 500;
   color: #777;
}