
.list-box{
  // position: sticky;
  // top: 0;
  // background-color: #f5f5f5;
  // z-index: 10;
}
.list-container{
  overflow: hidden;
  height: 50px;
  .list-wrap{
    display: flex;
    overflow-x: auto;
    padding: 18px 0;
    // margin: 0 -10px;
    padding-bottom: 20px;
    padding-top: 10px;
    // padding-right: 17px;
    li{
      list-style: none;
      margin: 0 5px;
      padding:5px 10px;
      transition: all 0.5s;
      a{
        color: inherit;
      }
      &.active{
        // border-bottom: 1px solid  #ff5252;
        background-color: #ff5252;
        border-radius:10px ;
        color:#ffff !important;
     
      }
    }
}
}