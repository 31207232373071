.pageNavbar {
  display: flex;
  align-items: center;
  padding: 15px 10px;
}

.pageNavbar .backLink {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.pageNavbar .deliveryText {
  flex-grow: 1;
  // text-align: center;
  
}

.pageNavbar .backIcon {
  margin-right: 5px;
}
