.allergies {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 25px;

	.allergies_item {
		display: flex;
		padding: 5px;
		align-items: center;
		.allergies_icon {
			width: 20px;
			height: 20px;
			min-height: unset;
			margin-right: 2px;
		}
	}
}
// .allergy_text {
// 	font-size: 9px !important;
// 	margin-bottom: 30px;
// 	font-style: italic;
// }
