$white-color: #ffffff;
$red-color: #fc7b7c;

.CardImage {
	border-radius: 10px 0 0 10px;
	// height: 100% !important;
	height: 100%;
	width: 100%;
	object-fit: contain;
}

// .productCard {
//   box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
//   // height: 123px;
//   border-radius: 10px;
//   position: relative;

// }
.radius {
	border-radius: 10px !important;
}
.orgPrice {
	font-size: 16px;
	text-decoration: line-through;
	color: #989898;
}

.price {
	position: relative;
	margin-left: 5px;
}

.cardText {
	font-size: 1rem !important;
}

.card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	width: calc(100% - 150px);
	// overflow: auto;
}
.productCardImageBox {
	width: 150px;
	min-width: 150px;
	display: flex;
	position: relative;
	&::after {
		content: "";
		position: relative;
		padding-bottom: 100%;
	}
	img {
		width: 100%;
		left: 0;
		right: 0;
		position: absolute;
	}
}
.addBasketButton {
	position: absolute;
	bottom: -10px;
	right: 10px;
	// left: 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #50c878;
	color: white;
	font-size: 1rem;
	// position: relative;
	cursor: pointer;
	border: none;
	margin-left: auto;
	&::before {
		content: "+";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 2.5rem;
	}
}
.addBasketButtons {
	margin-left: auto;
	height: 0 !important;
	// margin-top: 10px;
	position: absolute;
	right: 6px;
	bottom: 0;
	/* background-color: #dc3545; */
	font-size: 0.8rem;
	color: white;
	font-weight: bold;
	padding: 4px;
	z-index: 10;
}

.red-button {
	background-color: red;
	color: white;
	padding: 10px;
	border: none;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.button-content {
		display: flex;
		align-items: center;
	}

	.continue {
		margin-right: 10px;
	}

	.total {
		margin-right: 10px;
	}

	.price {
		font-weight: bold;
	}
}

/* Add the following styles to make the button sticky */

.continue-button-container {
	// position: fixed;
	// bottom: 0;
	// left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	padding: 10px;
	padding-top: 0;
}

.continue-button {
	// background-color: red;
	color: white;
	padding: 0 10px;
	border: none;
	border-radius: 5px;
	width: 100%;
	max-width: 400px;
	text-align: center;
}

.quantityButton {
	position: absolute;
	bottom: 10px;
	// left: 10px !important;
	display: flex;
	justify-content: center;
	box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	color: #50c878;
	background-color: white;
	font-size: 1rem;
	position: relative;
	cursor: pointer;
	border: none;
	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 2.5rem;
	}
}

.comeTop {
	z-index: 99;
}

.quantityButtonPlus {
	position: absolute;
	bottom: 10px;
	// left: 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #50c878;
	color: white;
	font-size: 1rem;
	position: relative;
	cursor: pointer;
	border: none;
	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 2.5rem;
	}
	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}
.quantityButtonMinus {
	position: absolute;
	bottom: 10px;
	// left: 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #50c878;
	color: white;
	font-size: 1rem;
	position: relative;
	cursor: pointer;
	border: none;
	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 2.5rem;
	}
}

.align {
	align-items: center;
}

.area {
	// background-color: #f5f5f5;
	// z-index: 100;
	// overflow-x: auto;
	// white-space: nowrap;

	margin-bottom: 4rem;
}

.product-section {
	position: absolute; /* Position the product section absolutely */
	top: 60px; /* Position it below the top bar */
	z-index: 1; /* Set a higher z-index to make it appear above the area section */
	width: 100%; /* Make it occupy the full width of the container */
	overflow-y: scroll; /* Add a vertical scrollbar if necessary */
	max-height: calc(100vh - 160px); /* Adjust the height to fit between the top bar and area section */
}

.empty-space {
	// height: 420px;
}

.button-container {
	display: flex;
	z-index: 100;
}

.button {
	flex: 1;
	margin-right: 0;
	z-index: 100;
	height: 60px;
	padding-top: 10px;
	border-radius: 0px 0px 0px 0px !important;
}

.totalButton {
	width: 25%;
	border-radius: 0px 0px 0px 0px !important;
}

.button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-top: 10px;
}

.button {
	height: 40px;

	align-items: center;
	margin-top: 10px;
}

.button-left {
	flex: 1;
	height: 50px;
	background-color: #50c878 !important;
	border-radius: 10px 0px 0px 10px !important;
	// border-radius: 3px  !important;
}
.button-left-disabled {
	flex: 1;
	height: 50px;
	background-color: #50c87886 !important;
	border-radius: 10px 0px 0px 10px !important;
}

.button-right {
	flex: 0.3;
	height: 50px;
	background-color: #f5f5f5 !important;
	color: #020202 !important;
	font-weight: 500;
	border-radius: 0px 10px 10px 0px !important;
}
